Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.btnExampleTitle = "CLICK ME";

exports.discoverHeading = ["BACK AND FORTH ALL","OVER THE WORLD","WE LOVE GOING PLACES","WITH YOU."];
exports.discoverSubHeading = ["ON DEMAND", "CONCIERGE"];
exports.discoverSubHeading2 = "A TAILORED JOURNEY";
exports.discoverSubHeadingPara = "Disrupt the Jet Charter industry by drastically reducing time required to manage a booking. GLOBAL value price transparency, fast and simple booking with less duplicate tasks and peace of mind by including gold standard carbon offsets as standard with every booking.";
exports.discoverSubHeadingPara2 = "Seamless information and document management system ensure we can drastically reduce booking times, customer frustration and data breaches."
exports.imageOverlayText = ["EXPERIENCE A", "SEAMLESS JOURNEY"]
exports.discoverMemberShipHeading = ["MEMBERSHIP", "COMING SOON"]
exports.discoverMemberShipHeading2 = "JOIN US"
exports.discoverMemberShipPara = "Disrupt the Jet Charter industry by drastically reducing time required to manage a booking. GLOBAL value price transparency, fast and simple booking with less duplicate tasks and peace of mind by including gold standard carbon offsets as standard with every booking."
exports.applyButton = "APPLY";
exports.comingSoonFleat=["OUR FLEET", "COMING SOON"]




exports.fetchImpactAPI = 'bx_block_self_content/impacts';
exports.fetchDiscoverAPI = '/bx_block_self_content/self_contents';
exports.fetchFoundersAPI = '/bx_block_self_content/founders';
exports.fetchQuestions = 'bx_block_question/questions';
exports.postAnswers = 'account_block/join_requests';

exports.discoverPageResponse = {
  section1 : {
    title : ["BACK AND FORTH ALL", "OVER THE WORLD", "WE LOVE GOING PLACES", "WITH YOU."],
    image : "IMAGE_LINK"
  },
  section2 : {
    title : ["ON DEMAND", "CONCIERGE"],
    plus : true,
    subTitle : "A TAILORED JOURNEY",
    description : [
      "Disrupt the Jet Charter industry by drastically reducing time required to manage a booking. GLOBAL value price transparency, fast and simple booking with less duplicate tasks and peace of mind by including gold standard carbon offsets as standard with every booking.",
      "Seamless information and document management system ensure we can drastically reduce booking times, customer frustration and data breaches."
    ]
  },
  section3 : {
    image : "IMAGE_LINK",
    title : ["EXPERIENCE A", "SEAMLESS JOURNEY"]
  },
  section4 : {
    title : ["MEMBERSHIP", "COMING SOON"],
    plus : false,
    subTitle : "JOIN US",
    description : [
      "Disrupt the Jet Charter industry by drastically reducing time required to manage a booking. GLOBAL value price transparency, fast and simple booking with less duplicate tasks and peace of mind by including gold standard carbon offsets as standard with every booking."
    ],
    accordian : [
      {
        title : "Fixed Rates",
        description : "Disrupt the Jet Charter industry by drastically reducing time required to manage a booking. GLOBAL value price transparency,"
      },
      {
        title : "Guaranteed Backup/Recovery",
        description : "Disrupt the Jet Charter industry by drastically reducing time required to manage a booking. GLOBAL value price transparency,"
      },
      {
        title : "365 Availability",
        description : "Disrupt the Jet Charter industry by drastically reducing time required to manage a booking. GLOBAL value price transparency,"
      },
      {
        title : "Zero backout Dates",
        description : "Disrupt the Jet Charter industry by drastically reducing time required to manage a booking. GLOBAL value price transparency,"
      }
    ],
    image : "IMAGE_LINK"
  },
  section5 : {
    title : ["OUR FLEET", "COMING SOON"],
    plus : false,
    subTitle : null,
    slider : {
      title : "our fleet",
      tabs : [
        {
          title : "Challenger 300",
          subTitle : "IMPROVING AIRTRAFFIC CONTROLS LETS AIRTRAFFIC",
          description : "Fast, spacious and loaded with amenities, the Bombardier Challenger 300 is one of the most popular super midsize business jets. Charter a Challenger 300 for private jet travel anywhere in North America, to Hawaii or across the Atlantic to Western Europe. As with all the aircraft in Clay Lacy’s nationwide fleet of business jets, our Challenger 300 charter rate offers you legendary value.",
          details : {
            "REGISTRATION" : "N396BC",
            "SEATS" : 9,
            "BEDS" : 3,
            "RANGE" : "3,100NM",
            "WIFI" : "Yes",
            "ATTENDANT" : "Yes",
            "LUGGAGE" : "106 CU FT",
            "LAVATORY" : 1,
            "CABIN WIDTH" : "7’ 2”",
            "CABIN LENGTH" : "28’ 6”",
            "CABIN HEIGHT" : "6’ 1”"
          },
          image : "IMAGE_LINK"
        },
        {
          title : "Challenger 604",
          subTitle : "IMPROVING AIRTRAFFIC CONTROLS LETS AIRTRAFFIC",
          description : "Fast, spacious and loaded with amenities, the Bombardier Challenger 300 is one of the most popular super midsize business jets. Charter a Challenger 300 for private jet travel anywhere in North America, to Hawaii or across the Atlantic to Western Europe. As with all the aircraft in Clay Lacy’s nationwide fleet of business jets, our Challenger 300 charter rate offers you legendary value.",
          details : {
            "REGISTRATION" : "A136DC",
            "SEATS" : 12,
            "BEDS" : 3,
            "RANGE" : "3,751NM",
            "WIFI" : "Yes",
            "ATTENDANT" : "Yes",
            "LUGGAGE" : "106 CU FT",
            "LAVATORY" : 1,
            "CABIN WIDTH" : "8’ 2”",
            "CABIN LENGTH" : "28’ 6”",
            "CABIN HEIGHT" : "6’ 1”"
          },
          image : "IMAGE_LINK"
        }
      ]
    }
  },
  section6 : {
    title : "2022 - FUTURE",
    tabs : [
      {
      title : ["Our","Mission"],
      description : "Fast, spacious and loaded with amenities, the Bombardier Challenger 300 is one of the most popular super midsize business jets. Charter a Challenger 300 for private jet travel anywhere in North America, to Hawaii or across the Atlantic to Western Europe. As with all the aircraft in Clay Lacy’s nationwide fleet of business jets, our Challenger 300 charter rate offers you legendary value."
      },
      {
        title : ["Our","Story"],
        description : "Fast, spacious and loaded with amenities, the Bombardier Challenger 300 is one of the most popular super midsize business jets. Charter a Challenger 300 for private jet travel anywhere in North America, to Hawaii or across the Atlantic to Western Europe. As with all the aircraft in Clay Lacy’s nationwide fleet of business jets, our Challenger 300 charter rate offers you legendary value."
      }
    ]
  },
  section7 : {
    title : ["FOUNDERS"],
    plus : false,
    subTitle : null,
    description : [
      "Disrupt the Jet Charter industry by drastically reducing time required to manage a booking. GLOBAL value price transparency, fast and simple booking with less duplicate tasks and peace of mind by including gold standard carbon offsets as standard with every booking.",
      "Seamless information and document management system ensure we can drastically reduce booking times, customer frustration and data breaches."
    ]
  },
  section9 : {
    title : ["BACK AND FORTH ALL", "OVER THE WORLD", "WE LOVE GOING PLACES", "WITH YOU."],
    image : null
  }
}

// Customizable Area End
