import React from "react";
import { Typography, Box, Grid, styled } from '@material-ui/core';
// Customizable Area Start
//@ts-ignore
import { motion as m } from 'framer-motion';
import { Formik, Form } from 'formik';
import { Button } from '@material-ui/core';
import PhoneInput from "react-phone-number-input";
import { discoverImage, discoverMobileImage, headerBlackBox } from "./assets";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackwardIosIcon from '@material-ui/icons/ArrowBackIos';
import { baseURL } from '../../../blocks/bulkuploading/src/BulkUploadingController';
//@ts-ignore
import { Helmet } from "react-helmet";
// Customizable Area End


import DiscoverController, { Props } from "./DiscoverController";
import { membershipDiary } from "./assets";
import { Process, Carousel, FeatureSection, FeaturewithImageSection } from "../../../components/src/shared";
import FixedText from "../../../components/src/shared/FixedText";
import { HTMLParser } from "../../../components/src/utils/html-parser";

export default class Discover extends DiscoverController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleBlink = this.handleBlink.bind(this)
    // Customizable Area End
  }

  // Customizable Area Start
  getPaddingBottom(currentQuestionIndex: number): string {
    const { isEmailInvalid, showEmailError } = this.state;

    // Check if the screen size is small
    if (window.outerWidth < 960 ) {
      return '16px';
    }

    // Conditions for larger screens
    const isQuestionIndexOne = currentQuestionIndex === 1;
    const shouldShowLargePadding = (isQuestionIndexOne && !isEmailInvalid && showEmailError?.length === 0) || currentQuestionIndex > 2;

    return shouldShowLargePadding ? '24px' : '16px';
  }

  headings(head: any = [], foot?: boolean) {
    // Helper function to get the largest text length
    const getLargestTextLength = (key: string): number => {
      if (!head || head.length === 0) return 0; // Check if head is defined and not empty
  
      return head.reduce((acc: number, curr: any) => {
        const textLength = curr.attributes?.[key]?.length || 0;
        return Math.max(acc, textLength);
      }, 0);
    };
  
    // Determine the key to use based on the 'foot' flag
    const textKey = foot ? "footline" : "headline";
    const largestTextLength = getLargestTextLength(textKey);
  
    // Determine if the device is mobile
    const isMobile = window.innerWidth < 960;
  
    // Set margin-left based on device type
    const marginLeft = isMobile ? "40px" : "150px";
  
    // Calculate the font size dynamically
    const windowWidthAdjustment = window.innerWidth - 60;
    const maxFontSize = 50;
    const fontSize =
      largestTextLength === 0
        ? maxFontSize
        : Math.min(maxFontSize, windowWidthAdjustment / largestTextLength);
  
    // Set styles dynamically
    const containerStyle = isMobile
      ? { paddingTop: 136, paddingBottom: 42 }
      : {
          height: foot ? "auto" : "100vh",
          paddingTop: "200px",
          paddingBottom: "200px",
        };
  
    const textStyle = (index: number): React.CSSProperties => ({
      fontWeight: 400,
      fontFamily: isMobile ? "Atyp Display TRIAL" : "Atyp Display",
      whiteSpace: "nowrap",
      marginLeft: index % 2 === 0 ? "0px" : marginLeft,
      fontSize: isMobile ? fontSize + 5 : fontSize - 10,
      lineHeight: isMobile ? "30px" : "50px",
    });
  
    // Render the component
    return (
      <Box className="rootContainer" style={containerStyle}>
        <Box>
          {head
            ?.sort((a: any, b: any) => parseInt(a.id) - parseInt(b.id))
            .map((item: any, index: number) => (
              <Typography
                variant="h1"
                color={index % 2 === 0 ? "secondary" : "initial"}
                key={item.id} // Ensure the key is a unique identifier
                style={textStyle(index)}
              >
                {item.attributes?.[textKey]}
              </Typography>
            ))}
        </Box>
      </Box>
    );
  }
  

  handlePhoneInput(currentQuestion: any, currentQuestionIndex: number, formik: { values: { answers: any[]; }; setFieldValue: (arg0: string, arg1: any) => void; }) {
    return (
      <> 
        <PhoneInput
        id="phoneblackBoxAnswer"
        style={{color: 'white', marginLeft: '15px'}}
        international
        numberInputProps={{ maxLength: 16 }}
        placeholder={currentQuestion.attributes.placeholder ?? ''}
        value={formik.values.answers[currentQuestionIndex] ?? ''}
        onChange={(e) => {
          const newAnswers = [...formik.values.answers];
          newAnswers[currentQuestionIndex] = e;
          formik.setFieldValue('answers', newAnswers);
        }}
      />
        {this.state.isPhoneInputValid && <Typography
        variant="h5"
        color="error"
        style={{ marginTop: "5px", fontSize: 10, fontWeight: 600 }}
        >
          Please provide valid phone number.
        </Typography>
        }
         {(this.state.showPhoneNumberError && !this.state.isPhoneInputValid) && <Typography
          variant="h5"
          id="PhoneNumberError"
          color="error"
          style={{ marginTop: "5px", fontSize: 10, fontWeight: 600 }}
        >
          {(this.state.showPhoneNumberError)}
        </Typography>
        }
      </>
    )
  }

  overlayImage() {
    if(window.outerWidth < 960 ) {
     return window.outerWidth < 400 ? "500px" : "600px";
    } else {
      return "870px";
    }
  }

  handleQuestionareForm(currentQuestion: any, currentQuestionIndex: number, formik: { values: { answers: any[]; }; setFieldValue: (arg0: string, arg1: any) => void; } ) {
    return (
      <> 
       <input
          id="blackBoxAnswer"
          required={currentQuestion?.id === '-2' || currentQuestion?.id === '-3' }
          type={currentQuestion?.attributes?.question_type == 'email' ? 'email' : 'text'}
          value={formik.values.answers[currentQuestionIndex]  ?? ''}
          placeholder={currentQuestion.attributes.placeholder ?? ''}
          onChange={(e) => {
            const newAnswers = [...formik.values.answers];
            newAnswers[currentQuestionIndex] = e.target.value;
            formik.setFieldValue('answers', newAnswers);
          }}
          onFocus={this.handleBlink}
          autoFocus={!(this.state.blinkCursor) ? true : false}
        />
        <div style={{height: '16px'}}>{this.state.blinkCursor && <div className="duplicateCursor"></div>}</div>
        {(this.state.isNameInValid && currentQuestion?.id === '-3') && <Typography
          variant="h5"
          color="error"
          id="nameerror"
          style={{ marginTop: "5px", fontSize: 10, fontWeight: 600 }}
        >
          Please provide correct name
        </Typography>
        }
        {(this.state.isEmailInvalid && currentQuestion?.id === '-2') && <Typography
          variant="h5"
          color="error"
          id="emailError"
          style={{ marginTop: "5px", fontSize: 10, fontWeight: 600,  }}
        >
          Please provide correct email
        </Typography>
        }
        {(this.state.showEmailError?.length > 0 && currentQuestion?.id === '-2' && !this.state.isEmailInvalid) && <Typography
          variant="h5"
          color="error"
          id="emailTakenError"
          style={{ marginTop: "5px", marginLeft: "12px", fontSize: 10, fontWeight: 600,  }}
        >
          {(this.state.showEmailError)}
        </Typography>
        }
      </>
    )
  }
 
  wrapperFeature(content: any) {
    const Container = styled('div')({
      "& #feature-section": {
        "@media only screen and (max-width: 768px)": {
          paddingTop: 62 - 20
        }
      },
      "& #subHeading": {
        "@media only screen and (max-width: 768px)": {
          paddingTop: 42 - 25,
        }
      }
    })

    return <Container>{content}</Container>
  }

  wrapperMembers(content: any) {
    const Container = styled('div')({
      "& #container": {
        "@media only screen and (max-width: 960px)": {
          marginTop: 84
        }
      },
      "& #subHeading": {
        "@media only screen and (max-width: 768px)": {
          marginTop: 42,
          marginBottom: 20
        }
      },
      "& #phrase": {
        "@media only screen and (max-width: 1200px)": {
          "& .MuiTypography-h1": {
            // fontSize: 36
          },
        },
        "@media only screen and (max-width: 768px)": {
          paddingBottom: 42
        }
      }
    })

    return <Container>{content}</Container>
  }

  wrapperProcess(content: any) {
    const Container = styled('div')({
      "@media only screen and (max-width: 959px)": {
        "& .MuiTypography-h2": {
          fontSize: 20
        }
      },
      "@media only screen and (max-width: 768px)": {
        "& hr": {
          height: "100px !important"
        },
        "& #process-content": {
          paddingTop: 62,
          paddingBottom: 0
        }
      }
    })

    return <Container>{content}</Container>
  }

  wrapperFounders(content: any) {
    const Container = styled('div')({
      "@media only screen and (max-width: 768px)": {
        "& #feature-section": {
          paddingTop: 60,
          paddingBottom: 26
        }
      }
    })

    return <Container>{content}</Container>
  }

  wrapperBottomLine(content: any) {
    const Container = styled('div')({
      "@media only screen and (max-width: 768px)": {
        "& > .MuiBox-root": {
          paddingBottom: "160px !important"
        }
      }
    })

    return <Container>{content}</Container>
  }

  wrapperHeaderImage(content: any) {
    const Container = styled('div')({
      "& #headerImage": {
        width: "100%",
        height: '800px',
        borderRadius: "0px 0px 20px 20px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat", 
        position: 'relative'
      },
      "& #blackQuestionareContainer": {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
         height: 'auto', 
         width: '100%', 
      },
      "& #blackBoxAnswer": {
        fontFamily: 'Atyp Display TRIAL', 
        fontWeight: 500, 
        color: '#C1C1C1', 
        marginLeft: '15px', 
        fontSize: '16px',
        border: 'none',
        background: 'none',
        padding: 0,
        margin: 0,
        outline: 'none',
        position: 'absolute',
        width: '60%',
      },
      "& #phoneblackBoxAnswer": {
        fontFamily: 'Atyp Display TRIAL', 
        fontWeight: 500, 
        color: '#C1C1C1', 
        marginLeft: '15px', 
        fontSize: '16px',
        border: 'none',
        background: 'none',
        padding: 0,
        margin: 0,
        outline: 'none',
        width: '100%',
      },
      '& #arrowLine': {
        border: '1px solid #FFFFFF',
        position: 'relative',
        height: '0.3px'
      },
      '& #arrowIcon': {
        color: '#FFFFFF', 
        fontSize: '16px'
      },
      '& #questonBlackBox':{
        position: 'absolute', 
        bottom: 0, 
        backgroundImage: `url(${headerBlackBox})`, 
        height: 'auto', 
        width: '50%', 
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat", 
        backgroundColor: '#000000', 
        borderRadius: '20px 20px 0px 0px',
      },
      '@media (max-width: 768px)': {
        '& #questonBlackBox': {
          width: '100%',
        }
      },
      '@media (min-width: 768px) and (max-width: 1024px)': {
        '& #questonBlackBox': {
          width: '60%',
        }
      },
      '& #nextButton': {
        fontFamily: 'Atyp Display TRIAL', 
        fontWeight: 500, 
        color: '#FFFFFF', 
        fontSize: '12px'
      },
      '& #prevButton': {
        fontFamily: 'Atyp Display TRIAL', 
        fontWeight: 500, 
        color: '#FFFFFF', 
        fontSize: '12px'
      },
      '& #questionHeading': {
        fontFamily: 'Atyp Display TRIAL',
        fontWeight: 500, color: '#FFFFFF',
        marginLeft: '15px',
        fontSize: '16px'
      },
      '& #questionBoxTopHeading': {
        fontFamily: 'Atyp Display', 
        fontWeight: 400, color: '#FFFFFF', 
        marginLeft: '40px', 
        lineHeight: '24px', 
        fontSize: '20px' 
      },
      
      '& #questionBoxInnerContainer': {
        display: 'flex', 
        flexDirection: 'column', 
        marginLeft: '10px', 
        width: '60%', 
        marginTop: '20px',
      },
      '@media (max-width: 600px)': {
        '& #questionBoxInnerContainer': {
          width: '70%',
        },
        '& #questionBoxTopHeading': {
          width: '70%',
          padding: '5px',
          display: 'flex',
          fontSize: '16px',
          marginLeft: '9%',
        },
        '& #blackBoxAnswer': {
          fontSize: '12px'
        },
        '& #questionHeading': {
          fontSize: '12px'
        },
        '& #nextButton': {
          marginRight: '10px',
          fontSize: '12px',
          fontWeight: 600,
          textTransform: "uppercase",
          textAlign: 'right',
          cursor: 'pointer',
          position:"relative",
          top:"1px",
         },
         '& #prevButton': {
          marginRight: '10px',
          fontSize: '12px',
          fontWeight: 600,
          textTransform: "uppercase",
          textAlign: 'right',
          cursor: 'pointer',
          position:"relative",
          top:"1px",
         },
         '& #questionIndex': {
          fontSize: '12px !important',
         },
         '& #arrowLine':{
            backgroundColor: '#FFFFFF',
            border: 'none',
            height: '1px'
         },
         '& #arrowIcon': {
          fontSize: '12px'
         }
      },
      '& #questionIndex': {
        fontFamily: 'Atyp Display TRIAL', fontWeight: 500, color: '#FFFFFF', fontSize: '16px', 
      }
    })

    return <Container>{content}</Container>
  }
 

  handleFormButton(currentQuestionIndex: number, questions: string | any[]) {
   return( 
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '28px' }}>
        {currentQuestionIndex > 0 && <>
        <Button
            data-test-id="btn-submit"
            variant="text"
            id="prevButton"
            onClick={this.handlePrevious}
          >
            {'Previous'}
        </Button>
        
        
        <ArrowBackwardIosIcon id="arrowIcon" style={{marginRight: window.outerWidth < 600 ? '-10px' : '-14px', marginBottom: window.outerWidth < 600 ? '2px' : '0px'}}/>
        </>
      }
        <div id="arrowLine" style={{width: currentQuestionIndex > 0 ? '70%' : '80%'}}></div>
        <ArrowForwardIosIcon id="arrowIcon" style={{marginLeft: '-8px'}}/>

        <Button
          data-test-id="btn-submit"
          variant="text"
          id="nextButton"
          type="submit"
          
        >
          {currentQuestionIndex === questions.length - 1 ? 'Send' : 'NEXT'}
        </Button>
      </div>
   )
  }
 
  renderBlackBox(currentQuestion: any, paddingBottom: any, currentQuestionIndex: number, formik: {
      values: { answers: any[]; } | { answers: any[]; }; setFieldValue: ((arg0: string, arg1: any
        // Customizable Area Start
        //@ts-ignore
      ) => void) | ((arg0: string
        //@ts-ignore
        , //@ts-ignore
        arg1: any) => void);
    }) {
    return (
      <Box id="questionBoxInnerContainer">
      <div style={{ display: 'flex' }}>
        <p id="questionIndex">{`0${currentQuestionIndex + 1}`}</p>
        <div style={{paddingBottom}}>
          <p id="questionHeading">{currentQuestion.attributes.question} {(currentQuestion?.id === '-2'|| currentQuestion?.id === '-3' || currentQuestion?.id === '-1' ) && <span id="astrik">*</span>}</p>
          {currentQuestion.attributes.question_type === "mobile" ?
            this.handlePhoneInput(currentQuestion, currentQuestionIndex, formik)
          : this.handleQuestionareForm(currentQuestion, currentQuestionIndex, formik)
          }
        </div>
      </div>
    </Box>
    )
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const headerBackgroundImage = window.outerWidth < 960  ? discoverMobileImage : discoverImage;
    const headingPara = this.headings(this.state.headLine);
    const { currentQuestionIndex, questions } = this.state;
    const sortedQuestions = questions?.sort((a: any, b: any) => a.id - b.id);
    const currentQuestion = sortedQuestions[currentQuestionIndex];
    const paddingBottom = this.getPaddingBottom(currentQuestionIndex);
    const headerSection = (<Box id='headerImage'  style={{ backgroundImage: `url(${headerBackgroundImage})` }}>
    <Box>{headingPara}</Box>
    <Box id='blackQuestionareContainer'>
      <Box id="questonBlackBox">
        <p id="questionBoxTopHeading">BE PART OF THE GLOBAL TRAVEL EXPERIENCE</p>
        <Formik
        initialValues={{ 
          answers: this.state.storedAnswers, 
          index: this.state.currentQuestionIndex, 
          currentQuestionId: currentQuestion?.id ?? ''
         }}
        validateOnChange={false}  
        validateOnBlur={false}
        onSubmit={this.handleSubmit}
      >
        {(formik) => {
          return(
          <Form>
            {currentQuestion && (
              this.renderBlackBox(currentQuestion, paddingBottom, currentQuestionIndex, formik)
            )}
            {this.handleFormButton(currentQuestionIndex, questions)}
          </Form>
        )}}
      </Formik>
      </Box>
    </Box>
  </Box>)
    const isMobile = window.outerWidth < 768;
    return (
      <div style={{ overflowX: "hidden" }}>
        <Helmet>
          <title>Global Discover: Discover Luxury Travel with Private Jet Bookings</title>
          <meta charSet="utf-8" />
          <meta name="description" content="Step into the world of ultimate luxury and convenience with Global's \'Discover\' page. Here, you'll embark on a journey that transcends ordinary travel, giving you access to an exclusive collection of private jets ready to take you anywhere your dreams desire. Immerse yourself in a curated selection of elite aircraft, each meticulously detailed and designed to offer an unparalleled flying experience. Whether you're seeking a quick escape to a tropical paradise or planning a business venture that demands efficiency and comfort, our comprehensive range of private jet options awaits your exploration. From sleek and modern interiors to cutting-edge amenities, you'll find every detail catered to your utmost satisfaction. Our user-friendly interface empowers you to browse, compare, and book with ease, ensuring that your travel aspirations become a seamless reality. Discover a new standard of personalised travel that transcends boundaries and redefines the art of flying. Global's \'Discover\' page is your gateway to a world where opulence meets efficiency, and where your journey is as remarkable as the destination itself. Your adventure begins here – start exploring the future of travel today." />
        </Helmet>
        <FixedText text="DISCOVER" />
        <m.div
          initial={{
            x: "100%"
          }}
          animate={{
            x: "0%"
          }}
          transition={{
            duration: 0.75,
            ease: "easeOut"
          }}
        >
          {this.wrapperHeaderImage(headerSection)}
          {
            this.wrapperFeature(
              <FeatureSection
                plus
                heading={this.state.mainContent?.title}
                subHeading={this.state.mainContent?.heading}
                para1={this.state.mainContent?.description}
                para2={""}
              />
            )
          }
          <Box sx={this.style.imageOverlay} className='hide-clock-in-this-container' style={{ backgroundImage: `url('${baseURL}${this.state.secondaryContent?.image?.trim()}')` }}>
            <Box style={{ width: isMobile ? "70%" : "45%", maxWidth: "700px" }}>
              <Typography variant="h1" color="primary" style={{ fontWeight: 400, textAlign: "left" }}>{this.state?.secondaryContent?.title[0]?.attributes?.title}</Typography>
              <Typography variant="h1" style={{ fontWeight: 400, textAlign: isMobile ? "center" : "right", color: "white" }}>{this.state?.secondaryContent?.title[1]?.attributes?.title}</Typography>
            </Box>
          </Box>

          {
            this.wrapperMembers(
              <FeaturewithImageSection
                heading={this.state.membership?.title}
                subHeading={this.state.membership?.heading}
                para1={this.state.membership?.description}
                image={`${baseURL}${this.state.membership?.image?.trim()}`}
                accordian={this.state.membership?.points}
              />
            )}
          {
            this.wrapperProcess(
              <Process
                heading={this.state.fleets?.title}
                subHeading="OUR FLEET"
                process={this.state.fleets?.fleet}
                hideSwipeTextOnMobile
                multipara
              />
            )
          }

          <Box className="rootContainer" sx={{ padding: { sm: "20px 40px", md: "20px 40px" } }}>
            <Box sx={{ width: "100%", borderBottom: "1px solid #2C62E0", padding: !isMobile ? "50px 0px" : 'unset', paddingBottom: isMobile ? 20 : 30, paddingTop: isMobile ? 12 : 0 }}>
              <Typography variant="h5" color="primary" style={{ letterSpacing: "4px", fontWeight: 600 }}>{this.state.about?.title}</Typography>
            </Box>
            {this.state.about?.content?.map((item: any, index: number) => {
              return (
                <Box key={item.attributes.about_content_titles.data[0].attributes.title} sx={{ width: "100%", borderBottom: "1px solid #2C62E0", padding: isMobile ? (index === 0 ? '22px 0px 42px 0px' : '42px 0px') : "60px 0px 80px 0px" }}>
                  <Grid container justifyContent="space-between">
                    <Grid item sm={12} md={6}>
                      <Typography variant="h1" color="secondary" style={{ fontWeight: 400, margin: "20px 0px", fontSize: window.outerWidth < 600 ? '25px' : '40px' }}>
                        {
                          item.attributes.about_content_titles.data.sort((a: any, b: any) => parseInt(a.id) - parseInt(b.id)).map((item: any, index: number) => {
                            return (
                              index === 0 ?
                                <span key={item.attributes.title}>{`${item.attributes.title} `}</span> :
                                <span key={item.attributes.title} style={{ color: "#000000" }}>{`${item.attributes.title} `}</span>
                            )
                          })
                        }
                      </Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Typography variant="h3" style={{fontWeight: 500, fontFamily:window.outerWidth < 960  ? 'Atyp Display TRIAL' : 'Inter', fontSize: window.outerWidth < 960  ? '15px' :'20px', lineHeight: window.outerWidth < 960  ? '18px' : '24.2px'}}>
                        <HTMLParser content={item.attributes.description} />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )
            })
            }
          </Box>

          {this.wrapperFounders(
            <FeatureSection
              heading={this.state.founderContent?.title}
              para1={this.state.founderContent?.description}
              para2={""}
            />
          )}

          <Carousel items={this.state.founders} />
          {this.state.footLine && this.wrapperBottomLine(this.headings(this.state.footLine, true))}
        </m.div>
      </div>
    );
    // Customizable Area End
  }
  // Customizable Area Start   
  style = {
    fixedContainer: {
      position: 'fixed',
      top: 400,
      left: 70,
      zIndex: 10
    } as React.CSSProperties,
    fixedText: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1.2rem",
      lineHeight: "28px",
      letterSpacing: "0.2em",
      color: "#2C62E0",
    } as React.CSSProperties,
    wrapper: {
      width: "95%"
    } as React.CSSProperties,
    imageContainer: {
      width: "100%",
      height: "100vh",
      borderRadius: "0px 0px 20px 20px",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    } as React.CSSProperties,
    imageOverlay: {
      width: "100%",
      height: this.overlayImage(),
      margin: "auto",
      borderRadius: "20px",
      backGroundColor: "red",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      display: "flex",
      justifyContent: "center",
      paddingBottom: "30px",
      alignItems: { xs: "center", md: "flex-end" },
      maxWidth: "1400px",
      boxSizing: "border-box"
    },
    imageMembership: {
      width: "100%",
      minHeight: "600px",
      height: "100%",
      borderRadius: "20px",
      backgroundImage: `url(${membershipDiary})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    } as React.CSSProperties,
    headerimageContainer: {
      width: "100%",
      height: "800px",
      borderRadius: "0px 0px 20px 20px",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    } as React.CSSProperties
  }
  // Customizable Area End
}
