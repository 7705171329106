import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { makeHttpMessage } from '../../../components/src/utils/http-message';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import Founders from "../../../components/src/types/founders";
import { isValidPhoneNumber } from "react-phone-number-input";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  founders : Founders[] | undefined,
  questions: any,
  dynamicQuestions: any,
  hasFocused: boolean,
  storedAnswers: any,
  fleets : any,
  membership : any,
  about : any,
  headLine : any,
  footLine  : any,
  founderContent : any,
  mainContent : any,
  secondaryContent : any,
  contents : any,
  loading : boolean,
  currentQuestionIndex: number,
  isPhoneInputValid: boolean,
  blinkCursor: boolean,
  isNameInValid: boolean,
  isEmailInvalid: boolean,
  showEmailError: any,
  showPhoneNumberError: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DiscoverController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchDiscoverPageDataMsgId : string
  fetchFoundersDataMsgId : string
  fetchQuestionsId: string
  postQuestionsAnswerId: string
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      founders : undefined,
      hasFocused: false,
      questions: [ {
        "id": "-1",
        "type": "question",
        "attributes": {
            "question": "What's your phone number ?",
            "placeholder": "(5555) 555-5555",
            "question_type": "mobile"
        }
      },
      {
          "id": "-2",
          "type": "question",
          "attributes": {
              "question": "What's your email ?",
              "placeholder": "john@doe.com",
              "question_type": "email"
          }
      },
      {
          "id": "-3",
          "type": "question",
          "attributes": {
              "question": "What's your name ?",
              "placeholder": "John Doe",
              "question_type": "string"
          }
      }],
      dynamicQuestions: [],
      isPhoneInputValid: false,
      isNameInValid: false,
      isEmailInvalid: false,
      storedAnswers: [],
      showEmailError: [],
      showPhoneNumberError: [],
      contents : [],
      fleets : {
        title : [""],
        fleet : [
          {
            name : "",
            discription : "",
            para : "",
            image : "",
            details : {
              "REGISTRATION" : "",
              "SEATS" : 0,
              "BEDS" : 0,
              "RANGE" : "",
              "WIFI" : "",
              "ATTENDANT" : "",
              "LUGGAGE" : "",
              "LAVATORY" : 0,
              "CABIN WIDTH" : "",
              "CABIN LENGTH" : "",
              "CABIN HEIGHT" : ""
            }
          }
        ]
      },
      membership : undefined,
      blinkCursor: true,
      about : undefined,
      headLine : undefined,
      footLine  : undefined,
      founderContent : undefined,
      mainContent : undefined,
      secondaryContent : {
        image : "",
        title : ""
      },
      loading : false,
      currentQuestionIndex: 0,
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId == this.fetchDiscoverPageDataMsgId) {
        if (errorReponse) {
          console.error(errorReponse);
        } else {
          this.setState({
            fleets : {
              title : responseJson?.data?.attributes?.fleet_titles?.data?.sort((a:any,b:any) => parseInt(a.id) - parseInt(b.id)).map((item:any) => item.attributes.title),
              fleet : responseJson?.data?.attributes?.fleets?.data?.map((item:any) => {
                return {
                  "name" : item.attributes.title,
                  "discription" : item.attributes.description,
                  "image" : item.attributes.image,
                  "details" : item.attributes.fleet_details !== "no fleet details available" ? {
                    "REGISTRATION" : item.attributes.fleet_details.data[0].attributes.registration,
                    "SEATS" : item.attributes.fleet_details.data[0].attributes.seats,
                    "BEDS" : item.attributes.fleet_details.data[0].attributes.beds,
                    "RANGE" : item.attributes.fleet_details.data[0].attributes.range,
                    "WIFI" : item.attributes.fleet_details.data[0].attributes.wifi,
                    "ATTENDANT" : item.attributes.fleet_details.data[0].attributes.attendant,
                    "LUGGAGE" : item.attributes.fleet_details.data[0].attributes.luggage,
                    "LAVATORY" : item.attributes.fleet_details.data[0].attributes.lavatory,
                    "CABIN WIDTH" : item.attributes.fleet_details.data[0].attributes.cabin_width,
                    "CABIN LENGTH" : item.attributes.fleet_details.data[0].attributes.cabin_length,
                    "CABIN HEIGHT" : item.attributes.fleet_details.data[0].attributes.cabin_height
                  } : {
                    "REGISTRATION" : "",
                    "SEATS" : 0,
                    "BEDS" : 0,
                    "RANGE" : "",
                    "WIFI" : "",
                    "ATTENDANT" : "",
                    "LUGGAGE" : "",
                    "LAVATORY" : 0,
                    "CABIN WIDTH" : "",
                    "CABIN LENGTH" : "",
                    "CABIN HEIGHT" : ""
                  },
                  "para" : ""
                }
              })
            },
            membership : {
              title : responseJson?.data?.attributes?.membership_details?.data?.attributes?.membership_titles?.data,
              points : responseJson?.data?.attributes?.membership_details?.data?.attributes?.membership_points?.data,
              heading : responseJson?.data?.attributes?.membership_details?.data?.attributes?.heading,
              image : responseJson?.data?.attributes?.membership_details?.data?.attributes?.image,
              description : responseJson?.data?.attributes?.membership_details?.data?.attributes?.description,
            },
            about : {
              title : responseJson?.data?.attributes?.about?.data?.attributes?.title,
              content : responseJson?.data?.attributes?.about?.data?.attributes?.about_contents?.data.sort((a:any,b:any) => parseInt(a.id) - parseInt(b.id))
            },
            headLine : responseJson?.data?.attributes?.headlines?.data,
            footLine  : responseJson?.data?.attributes?.footlines?.data,
            founderContent : {
              title : [{attributes:{title:responseJson?.data?.attributes?.founder_content?.data?.attributes?.title}}],
              description : responseJson?.data?.attributes?.founder_content?.data?.attributes?.description
            },
            mainContent : {
              title : responseJson?.data?.attributes?.discover_main_content?.data?.attributes?.discover_main_content_titles?.data,
              description : responseJson?.data?.attributes?.discover_main_content?.data?.attributes?.description,
              heading : responseJson?.data?.attributes?.discover_main_content?.data?.attributes?.heading,
              image : responseJson?.data?.attributes?.discover_main_content?.data?.attributes?.image
            },
            secondaryContent : {
              title : responseJson?.data?.attributes?.discover_secondary_content?.data?.attributes?.discover_secondary_content_titles?.data.sort((a:any,b:any) => parseInt(a.id) - parseInt(b.id)),
              image : responseJson?.data?.attributes?.discover_secondary_content?.data?.attributes?.image
            }
          })
        }
      }

      if (apiRequestCallId == this.fetchFoundersDataMsgId) {
        if (errorReponse) {
          console.error(errorReponse);
        } else {
          this.setState({
            founders : responseJson.data
          })
        }
      }

      if(apiRequestCallId == this.fetchQuestionsId) {
       this.handleFetchQuestion(errorReponse, responseJson)
      }

      if(apiRequestCallId == this.postQuestionsAnswerId) {
        this.handleFormError(errorReponse, responseJson)
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.fetchDiscoverPageData()
    this.fetchFounders()
    this.fetchQuestions()
    window.addEventListener('keypress', this.handleBlink)
    // Customizable Area End
  }

  // Customizable Area Start
  handleBlink() {

    if (!this.state.hasFocused) {
      this.setState({
        blinkCursor: false,
        hasFocused: true,
      }); 
    }
  }
  
  handleFetchQuestion = (errorReponse: any, responseJson: { data: any; }) => {
    if (errorReponse) {
      console.error(errorReponse);
    } else {
      this.setState((prevState) => ({
        questions: [...prevState.questions, ...responseJson.data],
        dynamicQuestions: responseJson.data
      }));
      this.setState(() => ({
        storedAnswers: new Array(this.state.questions.length).fill('')
      }))
    }
  }
  handleSubmit = (values: any) => {
    if(this.state.questions.length - 1 === this.state.currentQuestionIndex){
      this.setState({ storedAnswers: values.answers });
      const dynamicPayload = {};
      this.state.dynamicQuestions?.sort((a: any, b: any) => a.id - b.id).forEach((questionData:any, index:any) => {
        
        const question = questionData.attributes.question;
        const answer = values.answers[index + 3];
        //@ts-ignore
        dynamicPayload[question] = answer;
    });
      const payload = {
        "name" : values.answers[0],
        "email" : values.answers[1],
        "phone_number" : values.answers[2],
        "dynamic_questions" : dynamicPayload
      }
     this.postQuestionsAnswer(payload);
      
    } else { 
      if(this.state.questions[this.state.currentQuestionIndex]?.attributes.question_type == 'mobile' && !isValidPhoneNumber(`${values.answers[this.state.currentQuestionIndex]}`)) {
        this.setState({isPhoneInputValid: true, storedAnswers: values.answers })
      } else {
        const fullNamePattern = /^[^\s][a-zA-Z\s]*$/;
        const emailPattern = /^(?=.*@)(?=.*\.)[^\s@]+@[^\s@]+\.(com|org|net|edu|gov|info|biz|us|uk|ca|de|jp|cn|[a-zA-Z]{1,})$/;
        if((values.currentQuestionId === '-3' && !fullNamePattern.test(values?.answers[0])) || (values.currentQuestionId === '-2' && !emailPattern.test(values?.answers[1]))){
          values.currentQuestionId === '-3' && this.setState({isNameInValid: true, storedAnswers: values.answers });
          values.currentQuestionId === '-2' && this.setState({isEmailInvalid: true, storedAnswers: values.answers });
        } else {
        this.setState(
          (prevState) => ({
            currentQuestionIndex: prevState.currentQuestionIndex + 1,
          }),
          () => {
            this.setState({ storedAnswers: values.answers });
          }
        );
        this.setState({isPhoneInputValid: false});
        this.setState({isNameInValid: false});
        this.setState({isEmailInvalid: false});
      }
    }
   }
  };

  handlePrevious = () => {
    this.setState(
      (prevState) => ({
        currentQuestionIndex: prevState.currentQuestionIndex - 1,
      }),
      () => { }
    );
  }

  handleFormError = (errorReponse: any, responseJson: { errors?: { email?: any[], phone_number?: any[]}; }) => {
    if (errorReponse || responseJson.errors) {
      console.error(errorReponse);
      this.setState(() => ({
        showEmailError: responseJson?.errors?.email?.[0],
        showPhoneNumberError: responseJson?.errors?.phone_number?.[0]
      }))
      responseJson?.errors?.email?.[0] && this.setState(() => ({
        currentQuestionIndex: 1
      }))
      responseJson?.errors?.phone_number?.[0] && this.setState(() => ({
        currentQuestionIndex: 2
      }))
    } else {
      this.setState(() => ({
        storedAnswers: []
      }));
      this.props.navigation.navigate('Thankyou');
    }
  }
  
  postQuestionsAnswer(payload:any) : void {
    const header = {
      "Content-Type" : 'application/json'
    };
    const msg = makeHttpMessage({
      header,
      httpBody: {
        data: payload,
      },
      httpMethod : 'POST',
      url : configJSON.postAnswers
    });

    this.postQuestionsAnswerId = msg.messageId;

    runEngine.sendMessage(msg.id, msg);
 
  }

  fetchDiscoverPageData() : void {
    this.setState({
      loading : true
    })

    const header = {
      "Content-Type": 'application/json',
    };

    const httpBody = {};

    const msg = makeHttpMessage({
      header,
      httpBody,
      httpMethod: 'GET',
      url: configJSON.fetchDiscoverAPI,
    });

    this.fetchDiscoverPageDataMsgId = msg.messageId;
    runEngine.sendMessage(msg.id, msg);
  }

  fetchFounders() {
    const header = {
      "Content-Type" : 'application/json'
    };

    const httpBody = {}

    const msg = makeHttpMessage({
      header,
      httpBody,
      httpMethod : 'GET',
      url : configJSON.fetchFoundersAPI
    });

    this.fetchFoundersDataMsgId = msg.messageId;

    runEngine.sendMessage(msg.id, msg);
  }

  fetchQuestions() {
    const header = {
      "Content-Type" : 'application/json'
    };
    const msg = makeHttpMessage({
      header,
      url: configJSON.fetchQuestions,
      httpMethod: 'GET',
    });

    this.fetchQuestionsId = msg.messageId;

    runEngine.sendMessage(msg.id, msg);
  }
  // Customizable Area End
}
