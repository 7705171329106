// App.js - WEB
import React, { Component, useEffect } from "react";
import { View } from "react-native";
import firebase from 'firebase'
// @ts-ignore
import { connect } from 'react-firebase'

// @ts-ignore
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
// @ts-ignore
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import { TopNavLayout } from "../../components/src/shared";
import { PageWrapper } from "../../components/src/utils/page-wrapper";
import { RouterContextProvider } from "../../components/src/utils/router-context";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import { AnimatePresence } from 'framer-motion'


import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ScrollToTop from '../../components/src/utils/scroll-to-top';

import { useMediaQuery, useTheme } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnUtils from '@date-io/moment'
//@ts-ignore
import { Helmet } from "react-helmet";

import RestApiClientBlock from '../../framework/src/Blocks/RestApiClientBlock';
RestApiClientBlock.getInstance();

/*
import Cfavinodewidgetembedding from "../../blocks/cfavinodewidgetembedding/src/Cfavinodewidgetembedding";
import Breadcrumbnavigation from "../../blocks/breadcrumbnavigation/src/Breadcrumbnavigation";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Memberdirectory2 from "../../blocks/memberdirectory2/src/Memberdirectory2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Cfavinodeintegration from "../../blocks/cfavinodeintegration/src/Cfavinodeintegration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Customform from "../../blocks/customform/src/Customform";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Designporting from "../../blocks/designporting/src/Designporting";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfofflinecachingmechanism6 from "../../blocks/cfofflinecachingmechanism6/src/Cfofflinecachingmechanism6";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Designconversion5 from "../../blocks/designconversion5/src/Designconversion5";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Designconversion4 from "../../blocks/designconversion4/src/Designconversion4";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Cfserviceworkerintegration8 from "../../blocks/cfserviceworkerintegration8/src/Cfserviceworkerintegration8";
import Universallinks from "../../blocks/universallinks/src/Universallinks";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Designconversion6 from "../../blocks/designconversion6/src/Designconversion6";
import WorldClock from "../../blocks/worldclock/src/WorldClock";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Animationsandtransition3 from "../../blocks/animationsandtransition3/src/Animationsandtransition3";
*/
import { FooterLayout, FooterLayoutForDesktopOnly } from "../../components/src/shared"
import PrivacyTermsWeb from "../../blocks/termsconditions/src/PrivacyTermsWeb.web"
import JoinWebJoinContent from "../../blocks/user-profile-basic/src/JoinWeb2.web";
import ThankYouPage from "../../blocks/termsconditions/src/ThankYouPage2.web";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
// Customizable Area Start
import Discover from '../../blocks/landingpage/src/Discover.web'
import ResetPassword from '../../blocks/forgot-password/src/resetPassword.web'
import ForgotPassword from '../../blocks/forgot-password/src/forgotPassword.web'
import Impact from '../../blocks/landingpage/src/Impact.web'
import MyTripWeb from '../../blocks/user-profile-basic/src/MyTrips.web';
import Book from '../../blocks/user-profile-basic/src/Book.web'
import UserProfile from '../../blocks/user-profile-basic/src/UserProfile.web'
import { WorldClocksLayout, WorldClocksLayoutForDesktopOnly } from "../../components/src/shared/WorldClock";
import { AccountLayout } from '../../blocks/user-profile-basic/src/AccountLayout.web';

import SuccessPage from '../../blocks/forgot-password/src/SuccessPage.web'

import OfflineSyncMiddleware from '../../blocks/utilities/src/OfflineSyncMiddleware'
// Customizable Area End

// Customizable Area Start
const JetZeroTheme = createTheme({
  typography: {
    fontFamily: 'Atyp, sans-serif',
    h1: {
      fontSize: "50px",
      "@media (max-width:600px)": {
        fontSize: "25px",
      },
    },
    h2: {
      fontSize: "40px",
      "@media (max-width:600px)": {
        fontSize: "15px",
      },
    },
    h3: {
      fontSize: "20px",
      lineHeight: "28px",
      "@media (max-width:600px)": {
        fontSize: "15px",
        lineHeight: "21px"
      },
    },
    h4: {
      fontSize: "15px",
      "@media (max-width:768px)": {
        fontSize: "10px",
      },
    },
    h5: {
      fontSize: "15px",
      "@media (max-width:600px)": {
        fontSize: "8px",
      },
    },
  },
  palette: {
    primary: {
      main: '#2C62E0'
    },
    secondary: {
      main: '#C1C1C1'
    },
  },
  overrides: {
  }
});

function BackgroundWrapper({ children }: any): any {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    document.body.style.backgroundColor = matches ? 'black' : 'white'
  }, [matches]);

  return children;
};


const routeMap = {
  SuccessPage: {
    component: PageWrapper({
      page: SuccessPage,
      providers: [RouterContextProvider],
      layouts: [BackgroundWrapper, TopNavLayout],
    }),
    path: "/success",
    exact: true,
  },
  Home: {
    component: PageWrapper({
      page: Splashscreen,
      providers: [RouterContextProvider],
      layouts: [BackgroundWrapper, TopNavLayout],
    }),
    path: "/",
    exact: true,
  },
  Join: {
    component: PageWrapper({
      page: JoinWebJoinContent,
      layouts: [WorldClocksLayoutForDesktopOnly, FooterLayout, TopNavLayout],
      providers: [RouterContextProvider],
    }),
    path: '/join',
    exact: true
  },
  Login: {
    component: PageWrapper({
      page: Splashscreen,
      providers: [RouterContextProvider],
      layouts: [TopNavLayout],
    }),
    path: "/login",
    exact: true,
  },
  PrivacyTerms: {
    component: PageWrapper({
      page: PrivacyTermsWeb,
      layouts: [WorldClocksLayoutForDesktopOnly, FooterLayout, TopNavLayout],
      providers: [RouterContextProvider],
    }),
    path: '/privacyterms'
  },
  Thankyou: {
    component: PageWrapper({
      page: ThankYouPage,
      layouts: [BackgroundWrapper, WorldClocksLayoutForDesktopOnly, FooterLayout, TopNavLayout],
      providers: [RouterContextProvider],
    }),
    path: '/join/thank-you'
  },
  Discover: {
    component: PageWrapper({
      page: Discover,
      layouts: [WorldClocksLayoutForDesktopOnly, FooterLayout, TopNavLayout],
      providers: [RouterContextProvider],
    }),
    path: '/discover',
    exact: true
  },
  ResetPassword: {
    component: PageWrapper({
      page: ResetPassword,
      providers: [RouterContextProvider],
      layouts: [BackgroundWrapper, TopNavLayout],
    }),
    path: '/reset-password',
    exact: true
  },
  ForgotPassword: {
    component: PageWrapper({
      page: ForgotPassword,
      providers: [RouterContextProvider],
      layouts: [BackgroundWrapper, TopNavLayout],
    }),
    path: '/forgot-password',
    exact: true
  },
  Impact: {
    component: PageWrapper({
      page: Impact,
      layouts: [WorldClocksLayoutForDesktopOnly, FooterLayout, TopNavLayout],
      providers: [RouterContextProvider],
    }),
    path: '/impact',
    exact: true
  },
  MyTrips: {
    component: PageWrapper({
      page: MyTripWeb,
      layouts: [AccountLayout, FooterLayout, TopNavLayout],
      providers: [RouterContextProvider],
    }),
    path: '/my-trips',
    exact: true,
  },
  Book: {
    component: PageWrapper({
      page: Book,
      layouts: [AccountLayout, FooterLayout, TopNavLayout],
      providers: [RouterContextProvider],
    }),
    path: '/book',
    exact: true,
  },
  Account: {
    component: PageWrapper({
      page: UserProfile,
      layouts: [FooterLayout, TopNavLayout],
      providers: [RouterContextProvider],
    }),
    path: '/account'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  /*
    
    avinodewidgetembedding: {
    component: Cfavinodewidgetembedding,
    path: "/Cfavinodewidgetembedding"
  },
    eadcrumbnavigation: {
    component: Breadcrumbnavigation,
    path: "/Breadcrumbnavigation"
  },
    vigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
    mberdirectory2: {
    component: Memberdirectory2,
    path: "/Memberdirectory2"
    
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
    
    avinodeintegration: {
    component: Cfavinodeintegration,
    path: "/Cfavinodeintegration"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
    
  Customform: {
    component: Customform,
    path: "/Customform"
    
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
    PInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
    
  Contentmanagement3: {
    component: Contentmanagement3,
    path: "/Contentmanagement3"
  },
  Designporting: {
    component: Designporting,
    path: "/Designporting"
    
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Cfofflinecachingmechanism6: {
    component: Cfofflinecachingmechanism6,
    path: "/Cfofflinecachingmechanism6"
    
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: "/Servicespecificsettingsadmin2"
  },
    untryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
    talogue: {
    component: Catalogue,
    path: "/Catalogue"
    
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Designconversion5: {
    component: Designconversion5,
    path: "/Designconversion5"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
    },
    Rolesandpermissions: {
      component: Rolesandpermissions,
      path: "/Rolesandpermissions"
  },
    cialMediaAccountLoginScreen: {
      mponent: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
    Designconversion4: {
      component: Designconversion4,
      path: "/Designconversion4"
    },
   RequestManagement: {
      component: RequestManagement,
      path: "/RequestManagement"
    },
    SocialMediaAccountRegistrationScreen: {
   component: SocialMediaAccountRegistrationScreen,
          "/SocialMediaAccountRegistrationScreen"
    },
            ntLoginBlock: {
      component: EmailAccountLoginBlock,
            EmailAccountLoginBlock"
          
        iceworkerintegration8: {
    component: Cfserviceworkerintegration8,
                  iceworkerintegraion8"
    },
          llinks: {
               Universallinks,
            Universallinks"
          
   ForgotPassword: {
      component: ForgotPassword,
      path: "/ForgotPassword"
    },
    ForgotPasswordOTP: {
      component: ForgotPasswordOTP,
      path: "/ForgotPasswordOTP"
    },
    NewPassword: {
      component: NewPassword,
      path: "/NewPassword"
    },
    Designconversion6: {
      component: Designconversion6,
      path: "/Designconversion6"
    },
    WorldClock: {
      component: WorldClock,
      path: "/WorldClock"
    },
    TermsConditions: {
      component: TermsConditions,
      path: "/TermsConditions"
    },
    TermsConditionsDetail: {
      component: TermsConditionsDetail,
      path: "/TermsConditionsDetail"
    },
    TermsConditionsUsers: {
      component: TermsConditionsUsers,
      path: "/TermsConditionsUsers"
    },
    BulkUploading: {
      component: BulkUploading,
      path: "/BulkUploading"
    },
    CarouselDisplay: {
      component: CarouselDisplay,
      path: "/CarouselDisplay"
    },
    Uploadmedia3: {
      component: Uploadmedia3,
      path: "/Uploadmedia3"
    },
    LandingPage: {
      component: LandingPage,
      path: "/LandingPage"
    },
    EmailAccountRegistration: {
      component: EmailAccountRegistration,
      path: "/EmailAccountRegistration"
    },
    Animationsandtransition3: {
      component: Animationsandtransition3,
      path: "/Animationsandtransition3"
    },
  
  
     */
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});


const jetZeroQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000 * 60,   // 10 minutes 
    }
  }
});
// Customizable Area end

class App extends Component {
  // Customizable Area Start
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <MuiPickersUtilsProvider utils={DateFnUtils}>
        <ThemeProvider theme={JetZeroTheme} >
          <QueryClientProvider client={jetZeroQueryClient}>

            <ReactQueryDevtools />

            <View style={{ backgroundColor: 'white' }}>
              <OfflineSyncMiddleware />
              <AnimatePresence initial={false}>
                <ScrollToTop>
                  <Helmet>
                    <title>Global: Discover Luxury Travel with Private Jet Bookings</title>
                    <meta charSet="utf-8" />
                    <meta name="description" content="With Global's On-Demand private jet booking service, you can enjoy the luxuries of private air travel, including enhanced privacy, personalized services, and the utmost comfort throughout your journey. Our experienced team takes care of all the details, from flight planning to ground transportation arrangements, ensuring a seamless travel experience from start to finish. As we only work on an invite only basis, you enjoy priority access to our fleet network, ensuring that your travel plans are met promptly and efficiently. With a simple and streamlined booking process, you can request a private jet on-demand, allowing you to fly on your schedule. Our comprehensive network of trusted partners and operators ensures a wide selection of aircraft options to suit your specific preferences and travel needs." />
                  </Helmet>
                  {WebRoutesGenerator({ routeMap })}
                </ScrollToTop>
              </AnimatePresence>
            </View>

          </QueryClientProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
  // Customizable Area End
}

export default App;
