import React, { ReactNode, useEffect, useState, useLayoutEffect } from "react";
import { Box, styled, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { HideContainer } from "./HideContainer";
import { useRouteInfo } from "../utils/router-context";
import { useWorldClock } from "../api/hooks/basicProfile.hooks";

function getTime(selectedTimezone: string) {
  const time = new Date().toLocaleTimeString("en-US", {
    timeZone: selectedTimezone,
    hour12: false,
  });
  return time;
}

function WorldClocks({
  color,
  account,
  desktop,
  reset
}: {
  color: string;
  account?: boolean;
  desktop?: boolean;
  reset?: boolean
}) {
  const theme = useTheme();
  const { data: countryList } = useWorldClock();
  const [times, setTimes] = useState<any>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(()=>{
    countryList && prepareTimeList()
    const timeInterval = setInterval(() => {
      prepareTimeList();
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [countryList])

  const prepareTimeList = () => {
    countryList &&
      setTimes(
        countryList.map((item: any) => {
          return {
            country: item.country.split("/")[1].replace("_", " "),
            time: getTime(item.country),
          };
        })
      );
  };

  useLayoutEffect(() => {
    const callback = () => {
      let containers: NodeList = document.querySelectorAll(
        "#overlap-container, .hide-clock-in-this-container"
      );
      let watcher: HTMLElement | any = document.getElementById(
        "floating-world-clock"
      );
      if (!watcher) return;

      let bottomOfWatcher = watcher.getBoundingClientRect().bottom;
      let topOfWatcher = watcher.getBoundingClientRect().top;
      let flagArray: Array<boolean> = [];

      containers.forEach((element: HTMLElement | any) => {
        let margin = 100;
        let { top, bottom } = element.getBoundingClientRect();
        let inside =
          top <= topOfWatcher + margin && bottom + margin >= bottomOfWatcher;
        flagArray.push(inside);
      });

      let isVisble = flagArray.filter((item) => item == true).length > 0;
      let currentVisibility = isVisble ? "hidden" : "visible";
      let previousVisibility = watcher.style.visibility;
      if (currentVisibility != previousVisibility)
        watcher.style.visibility = currentVisibility;
    };

    setTimeout(()=>callback(), 1000)
    
    document.addEventListener("scroll", callback);
    return () => {
      document.removeEventListener("scroll", callback);
    };
  }, []);

  return (
    <Container
      mobile={isMobile}
      account={account}
      desktop={desktop}
      reset={reset}
      id="floating-world-clock"
    >
      { times.map((time: any) => {
        return (
          <React.Fragment key={time.country}>
            <div style={{ color: "#2C62E0", textTransform:"uppercase" }}>{time.country}</div>
            <div style={{ color: color, textTransform:"uppercase" }}>{time.time}</div>
          </React.Fragment>
        );
      }) }
    </Container>
  );
}

function useWorldClocksColor() {
  const { path } = useRouteInfo();

  const isBlackBackgroundPage =
    path == "/success" ||
    path == "/login" ||
    path == "/" ||
    path == "/forgot-password" ;

  return isBlackBackgroundPage ? "white" : "black";
}

export function WorldClocksLayoutForDesktopOnly({
  children,
}: {
  children: ReactNode;
}) {
  const theme = useTheme();
  const matches = window.outerWidth <= 1150;
  const color = useWorldClocksColor();

  return (
    <>
      <HideContainer hideWhen={matches}>
        <WorldClocks color={color} desktop={true} />
      </HideContainer>
      {children}
    </>
  );
}

export function WorldClocksLayout({ children }: { children: ReactNode }) {
  const color = useWorldClocksColor();

  return (
    <>
      <WorldClocks color={color} />
      {children}
    </>
  );
}

const Container = styled(Box)(({ mobile, account, desktop,reset }: any) => ({
  right: !mobile ? 100 : reset ? "auto" : 50,
  bottom: !mobile ? 60 : 30,
  marginRight: desktop ? -70 : 0,
  zIndex: 10,
  gridGap: 28,
  fontSize: 15,
  gridRowGap: 4,
  color: "white",
  fontWeight: 600,
  display: "inline-grid",
  gridTemplateColumns: "1fr 1fr",
  position: account ? "absolute" : "fixed",
}));

export default WorldClocks;
