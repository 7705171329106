// Customizable Area Start
import React from "react";
import clsx from "clsx";
import Alert from "@material-ui/lab/Alert";
import PhoneInput from "react-phone-number-input";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  HideContainer,
  JetZeroCustomInput,
} from "../../../components/src/shared";
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "15px",
    paddingBottom: "15px",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  lastQuestion: {
    borderBottom: "0px solid",
  },
  container: {
    margin: 0,
    [theme.breakpoints.down("xs")]: {},
  },
  heading: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.2rem",
    lineHeight: "28px",
    letterSpacing: "0.2em",
    color: "#2C62E0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
      lineHeight: "14px",
    },
  },
  approchText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "40px",
    textTransform: "uppercase",
  },
  labeltext: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "21px",
    },
  },
  subTitleText: {
    color: "#999",
  },
  text1Container: {
    marginTop: "42px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  text2Container: {
    marginTop: "20px",
    display: "flex",
    width: "100%",
    // height:'5vh',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  blueColor: {
    color: theme.palette.primary.main,
    fontSize: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  inputField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0,
        outline: 0,
      },
      "&:hover fieldset": {
        border: 0,
        outline: 0,
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        border: 0,
        outline: 0,
      },
    },
    "& .MuiInputLabel-root": {
      fontFamily: "'Atyp Display', sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "30px",
      lineHeight: "40px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    "& .MuiInputBase-input": {
      borderBottom: 0,
      outline: 0,
    },
  },
  alertsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "10px 0",
    "& > *": {
      marginRight: "10px",
      animationDelay: "0.4s",
      animationName: "$shake",
      animationDuration: "1s",
      animationTimingFunction: "ease-in-out",
      animationFillMode: "both",
    },
  },
  text: {
    fontSize: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  inputContainer: {
    position: "relative",
    "& label": {
      color: "#c9c9c9",
      position: "absolute",
      bottom: "16px",
      left: "10px",
    },
  },
  spanAstik : {
    color: theme.palette.primary.main,
    padding: '0 2px',
  },
  phoneInput: {
    fontSize: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  }
}));
// Customizable Area End

// Customizable Area Start
export interface Props {
  questionType: string;
  question: string;
  placeHolder: string;
  questionNumber: number;
  lastQuestion?: boolean;
  value: string;
  showAlert: boolean;
  handleOnChange: (val: string) => void;
  hideAsterisk?: boolean;
  phoneError?: boolean
}
// Customizable Area End

// Customizable Area Start
export default function QuestionWeb(props: Props) {
  const classes = useStyles();
  const {
    showAlert,
    questionType,
    question,
    handleOnChange,
    value,
    lastQuestion,
    questionNumber,
    placeHolder,
    hideAsterisk,
    phoneError
  } = props;

  return (
    <>
      <Grid
        container
        className={clsx(classes.root, {
          [classes.lastQuestion]: lastQuestion,
        })}
      >
        <Grid item lg={1} sm={1} xs={2}>
          <Typography className={classes.blueColor}>
            {String(questionNumber).padStart(2, "0")}
          </Typography>
        </Grid>

        <Grid item lg={10} sm={10} xs={10}>
          <Grid item lg={12} sm={12} xs={12}>
            <label htmlFor={question}>
              <Typography className={classes.text} style={{ fontWeight: 600 }}>
                {question}{!hideAsterisk && <span className={classes.spanAstik}>{' *'}</span>}
              </Typography>
            </label>
          </Grid>

          <Grid item lg={12} sm={8} xs={12}>
            {questionType === "mobile" ? (
              <>
                <PhoneInput
                  className={classes.phoneInput}
                  id={question}
                  international
                  value={value}
                  aria-label={question}
                  placeholder={placeHolder}
                  numberInputProps={{ maxLength: 15 }}
                  onChange={(value: any) => handleOnChange(value)}
                />
                {phoneError && <Typography
                  variant="h5"
                  color="error"
                  style={{ marginTop: "5px", fontSize: 10, fontWeight: 600 }}
                >
                  Please provide valid phone number.
                </Typography>}
                <Typography
                  variant="h5"
                  style={{ marginTop: "5px", fontSize: 10, fontWeight: 600 }}
                >
                  Note: Do not add '0' before number
                </Typography>
              </>
            ) : (
              <JetZeroCustomInput
                id={question}
                onChange={(e: any) => handleOnChange(e.target.value)}
                value={value}
                placeholder={placeHolder}
                hideAsterisk={hideAsterisk}
                required={!hideAsterisk}
                aria-label={question}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.alertsContainer}>
        <HideContainer hideWhen={!showAlert}>
          <Alert
            aria-label={"field is required"}
            data-testid="alert"
            severity="error"
          >
            {"field is required"}
          </Alert>
        </HideContainer>
      </div>
    </>
  );
}
// Customizable Area End