import React, { useEffect, useState } from "react";
import { Typography, Box } from '@material-ui/core';
import { HeadingText } from "./HeadingText";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { HideContainer } from "./HideContainer";
import { HTMLParser } from "../utils/html-parser";
import clsx from 'clsx'

interface Props {
  heading?: any,
  subHeading?: string,
  para1?: string,
  para2?: string,
  plus?: boolean
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    gap: "66px",
    padding: "160px 0px 90px 0px",
    width: "100%",
    [theme.breakpoints.down('sm')] : {
      padding: "60px 0px 60px 0px",
    }
  },
  blueLine: {
    height: "auto",
    width: "2px",
    backgroundColor: theme.palette.primary.main
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "25px"
  },
  subHeadingText: {
    letterSpacing: "0.3rem",
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '40px',
    fontFamily: 'Atyp Display TRIAL',
    [theme.breakpoints.down('sm')] : {
      lineHeight: '18px',
      fontSize: '15px',
     }
  },
  para1Text: {
    maxWidth: "1000px",
    fontWeight: 500,
    fontFamily: 'Inter',
    color: '#000000',
    lineHeight: '24.2px',
    [theme.breakpoints.down('sm')] : {
     marginTop: -5,
     marginBottom: 10,
     fontFamily: 'Atyp Display TRIAL',
     lineHeight: '18px',
     fontSize: '15px',
    }
  },
  para2Text: {
    maxWidth: "1000px",
    fontWeight:400,
    textAlign : "justify",
  },
}));


export function FeatureSection({ heading, subHeading, para1, para2, plus }: Readonly<Props>) {

  //Making responsive
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [headingArr, setHeadingArr] = useState<string[]>([])
  const classes = useStyles();

  useEffect(()=>{
    console.log(heading, "Heading")
    const tempArr = heading?.sort((a:any,b:any) => parseInt(a.id) - parseInt(b.id))?.map((item:any) => {
      return item?.attributes?.title
    })
    setHeadingArr(tempArr)
  },[heading])

  return (
    <Box className="rootContainer">
      <Box id='feature-section' className={clsx(classes.wrapper)}>

        <Box sx={{ display: matches ? "none" : "block" }} className={classes.blueLine}></Box>

        <Box className={classes.textWrapper}>

          <HideContainer hideWhen={!heading}>
            <HeadingText heading={headingArr} plus={plus} width={window.outerWidth < 768 ? window.outerWidth < 400 ? 'auto' : 'fit-content' : 355}/>
          </HideContainer>

          { subHeading && 
            <Typography id='subHeading' variant="h4" color="primary" className={classes.subHeadingText}>
            {subHeading}
          </Typography>}

          <Typography variant="h3" className={classes.para1Text}>
            <HTMLParser content={para1}/>
          </Typography>

          <Typography style={{display: para2 ? "block" :"none"}} variant="h3" className={classes.para2Text}>
            <HTMLParser content={para2}/>
          </Typography>
        </Box>

      </Box>
    </Box>
  )
}


