// Customizable Area Start
import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Checkbox,
  Button,
  useMediaQuery,
  FormControlLabel,
  Box,
  styled,
} from "@material-ui/core";
import clsx from "clsx";
import { flipElements } from "../../../components/src/utils/css-flip";
import { LoginForm2 } from "../../email-account-login/src/LoginForm2.web";
import {
  useRouteInfo,
  useNavigate,
} from "../../../components/src/utils/router-context";
import { discoverMobileImage } from "../../landingpage/src/assets";
import { motion as m, AnimatePresence } from "framer-motion";

import { PasswordVisibility } from '../../../components/src/shared';
import { useTheme } from "@material-ui/styles";
import { Link } from "react-router-dom";

// @ts-ignore
import classes from "./Splasscreen.module.css";
import WorldClocks from "../../../components/src/shared/WorldClock";
import { JetZeroTextField } from "../../../components/src/design-system";
import { Formik, Form } from "formik";

import { useLogin } from "../../../components/src/api/hooks/account.hooks";

const variants2 = {
  shake: {
    x: [200, -200, 200, -200, 200, 0],
  },
  noShake: {
    x: 0,
  },
};

export function SplashScreenContent2() {
  const theme2 = useTheme();
  // @ts-ignore
  const matches2 = useMediaQuery(theme2.breakpoints.down("sm"));
  const [errorMsg2, seterrorMsg2] = useState("");
  let timer2: any = "";
  let navigateToAccount2: any = "";

  useEffect(() => {
    return () => {
      clearTimeout(timer2);
      clearTimeout(navigateToAccount2);
    };
  }, []);

  const navigate2 = useNavigate();

  const inviteOnlyRef2 = useRef<any>();
  const containerRef2 = useRef<any>();
  const whiteJetRef2 = useRef<any>();
  const arrowRef2 = useRef<any>();

  const [containerState2, setcontainerState2] = useState("collapsed");

  const { path } = useRouteInfo();
  let isLoginPage2 = path.includes("login");

  const lock2 = useRef(true);

  function lockDown2() {
    lock2.current = false;
    setTimeout(unlock2, 1000);
  }

  function unlock2() {
    lock2.current = true;
  }

  function jetEnterance2() {
    flipElements(() => {
      setcontainerState2("expanded");
    }, [
      { element: inviteOnlyRef2.current, dataset: "enter" },
      { element: whiteJetRef2.current, dataset: "enter" },
      { element: arrowRef2.current, dataset: "enter" },
    ]);
  }

  function jetLeave2() {
    flipElements(() => {
      setcontainerState2("login");

      activateRedirectToAboutPage2();
    }, [
      { element: inviteOnlyRef2.current, dataset: "leave" },
      { element: whiteJetRef2.current, dataset: "leave" },
      { element: arrowRef2.current, dataset: "leave" },
    ]);
  }

  function clickHandler2(_event: any) {
    try {
      if (!lock2.current) return;

      lockDown2();

      let currentState2 = containerRef2.current.dataset.state;

      if (currentState2 == "collapsed") requestAnimationFrame(jetEnterance2);

      if (currentState2 == "expanded") requestAnimationFrame(jetLeave2);
    } catch (error) {
      console.error(error);
    }
  }

  function redirectToJoinPage2() {
    navigate2("/join");
  }

  function activateRedirectToAboutPage2() {
    function redirectToAboutPage2(event: any) {
      try {
        if (event?.target?.innerHTML?.toLowerCase() != "apply now")
          navigate2("/join");
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    if (containerRef2 && containerRef2.current) {
      setTimeout(() => requestAnimationFrame(clickHandler2), 3000);
      setTimeout(() => requestAnimationFrame(clickHandler2), 8000);
    }
    const token2 = localStorage.getItem("token");
    if (token2) {
      sessionStorage.setItem('token', token2)
      navigateToAccount2 = setTimeout(() => {
        navigate2("/account#myTrip");
      }, 10000);
    }
  }, []);

  useEffect(() => {
    if (isLoginPage2) activateRedirectToAboutPage2();
  }, [isLoginPage2]);

  return (
    <div style={{ overflowX: "hidden", position: "relative" }}>
      {(containerState2 === "login" || isLoginPage2) && matches2 && (
        <LoginFormWeb2 containerRef2={containerRef2} redirectToJoinPage2={redirectToJoinPage2} />
      )}

      <AnimatePresence>
        <m.div
          initial={{
            x: "100%",
          }}
          animate={{
            x: "0%",
          }}
          transition={{
            duration: 0.75,
            ease: "easeOut",
          }}
        >
          <div
            ref={containerRef2}
            data-state={isLoginPage2 ? "login" : containerState2}
            className={classes.container}
          >
            <div className={classes.layer1}>
              <Typography
                ref={inviteOnlyRef2}
                className={clsx(classes.inviteOnly)}
              >
                {"INVITE ONLY"}
              </Typography>
            </div>

            <div className={classes.layer2}>
              <span className={classes.jetContainer}>
                <img
                  ref={whiteJetRef2}
                  src="/assets/white-jet.svg"
                  alt="white jet"
                  className={classes.whiteJet}
                />
              </span>
            </div>

            <div className={classes.layer3}>
              <Typography className={clsx(classes.global, classes.whiteColor)}>
                {"GLOBAL"}
                <sup>{"TM"}</sup>
              </Typography>
            </div>

            <div ref={arrowRef2} className={classes.layer4}>
              <Typography className={classes.scrollText1}>
                {"SCROLL"}
              </Typography>

              <div className={classes.arrowContainer}>
              <div style={{position:'relative'}} className={classes.line}><div className={classes.arrow}></div></div>
              </div>
            </div>

            <div className={classes.layer5}>
            </div>

            <div className={classes.layer6}>
              <div className={classes.layer6Content}>
                <LoginForm2 errorMsg2={errorMsg2} setLoginErrorMsg2={seterrorMsg2} />

                <div className={classes.layer6ActionContainer}>
                  <div className={classes.layer6ArrowContainer}>
                    <div style={{position:'relative'}} className={classes.line}><div className={classes.arrow}></div></div>
                   
                  </div>

                  <m.div
                    animate={
                      errorMsg2 === "Account not found, or not activated"
                        ? "shake"
                        : "noShake"
                    }
                    variants={variants2}
                    transition={{
                      type: "spring",
                    }}
                  >
                    <Button
                      onClick={redirectToJoinPage2}
                      variant="text"
                      className={classes.applyNowBtn}
                    >
                      <span style={{position:"relative", top:"1px",}}>{"APPLY NOW"}</span>
                    </Button>
                  </m.div>
                  {!matches2 && <WorldClocks color={(containerState2 === "login" || isLoginPage2) ? 'black' : 'white'}/>}
                </div>
              </div>
            </div>
          </div>
        </m.div>
      </AnimatePresence>
    </div>
  );
}

const FORM_INITIAL_VALUES2 = {
  username: "",
  password: "",
  rememberMe: false,
};

function LoginFormWeb2({ redirectToJoinPage2, containerRef2 }: any) {
  const navigate3 = useNavigate();
  const { mutate: login3, isLoading:isLoading3 } = useLogin();

  const [showPassword3, setShowPassword3] = useState<boolean>(false)
  const [errorMsg3, seterrorMsg3] = useState("");
  const isMobileScreen3 = useMediaQuery("(max-width: 959px)");
  const [hideTillComplete3, setHideTillComplete3] = useState(false);
  const disableUnderline3 = !isMobileScreen3;

  useEffect(() => {
    setTimeout(() => setHideTillComplete3(true), 200);
  }, []);

  useEffect(() => {
    if (containerRef2.current && hideTillComplete3) {
      containerRef2.current.style.display = "none";
    }
  }, [containerRef2.current, hideTillComplete3]);

  if (!hideTillComplete3) return <></>;

  const setValues = (token2:any, refresh_token2:any, id2:any) => {
    localStorage.setItem('token', token2);
    localStorage.setItem('refresh_token', refresh_token2);
    localStorage.setItem('id', id2);
    sessionStorage.setItem('token', token2);
    sessionStorage.setItem('refresh_token', refresh_token2);
    sessionStorage.setItem('id', id2);
  }

  const removeValues = (token2:any, refresh_token2:any, id2:any) => {
    sessionStorage.setItem('token', token2);
    sessionStorage.setItem('refresh_token', refresh_token2);
    sessionStorage.setItem('id', id2);
  }

  return (
    <Container2>
      <TypoTitle2>INVITE ONLY</TypoTitle2>
      <Formik
        initialValues={FORM_INITIAL_VALUES2}
        onSubmit={(values) => {
          if (!values.username && !values.password) {
            seterrorMsg3("Username and password are required.");
            return;
          }
          if (!values.username) {
            seterrorMsg3("Username is required.");
            return;
          }
          if (!values.password) {
            seterrorMsg3("Password is required.");
            return;
          }
          seterrorMsg3("");
          login3({ email: values.username, password: values.password }, {
            onSuccess(response: any) {
              try {
                seterrorMsg3('');
                const { meta: { token:token2, refresh_token:refresh_token2, id:id2, checked_terms_and_policy:checked_terms_and_policy2 } } = response;
                values.rememberMe ? setValues(token2, refresh_token2, id2) : removeValues(token2, refresh_token2, id2);
                navigate3( checked_terms_and_policy2 ? '/account#myTrip' : '/account#account');
              } catch (error) {
                console.error(error);
              }
            },
            onError(error) {
              try {
                const { errors: [{ failed_login: errorMessage }] } = error;
                seterrorMsg3(errorMessage);
              } catch (error) {
                console.error(error);
              }
            },
          });
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formik: any) => (
          // @ts-ignore
          <Form translate="yes" className={classes.form}>
            <JetZeroTextField
              inputProps={{
                "data-test-id": "username",
              }}
              className={classes.input}
              label=""
              placeholder="USERNAME"
              InputProps={{ disableUnderline:disableUnderline3 }}
              fullWidth={isMobileScreen3}
              value={formik.values.username}
              onChange={(event) =>
                formik.setFieldValue("username", event.target.value)
              }
            />
            <Box sx={{ height: 42 }} />
            <JetZeroTextField
              inputProps={{
                "data-test-id": "password",
              }}
              className={classes.input}
              label=""
              placeholder="PASSWORD"
              type={showPassword3 ? "text" : "password"}
              InputProps={{ 
                disableUnderline:disableUnderline3,
                endAdornment:
                  <PasswordVisibility showPassword={showPassword3} togglePassword={()=>setShowPassword3(!showPassword3)}/>
              }}
              fullWidth={isMobileScreen3}
              value={formik.values.password}
              onChange={(event) =>
                formik.setFieldValue("password", event.target.value)
              }
            />

            <Box sx={{ display: "flex", paddingTop: 10, alignItems: "center" }}>
              <CheckBoxField2>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "black" }}
                      // @ts-ignore
                      inputProps={{ "data-test-id": "rememberMe" }}
                      checked={formik.values.rememberMe}
                      onChange={(event) =>
                        formik.setFieldValue("rememberMe", event.target.checked)
                      }
                      name="rememberMe"
                    />
                  }
                  label={<span style={{position:"relative", top:"1px", color: "black"}}>Remember me</span>}
                />
              </CheckBoxField2>
              <div style={{ flexGrow: 1 }}></div>
              <StyledLink2 to="/forgot-password">Forgot password?</StyledLink2>
            </Box>

            {errorMsg3 &&
              <>
                <Box sx={{ height: 8 }} />
                <ErrorMessage2
                  animate={
                    errorMsg3 === "Account not found, or not activated"
                      ? "shake"
                      : "noShake"
                  }
                  variants={variants2}
                  transition={{
                    type: "spring",
                  }}
                >
                  {errorMsg3}
                </ErrorMessage2>
              </>
            }

            <Box sx={{ height: 46 }} />
            <LoginButton2
              data-test-id="btn-submit"
              type="submit"
              variant="text"
              disabled={isLoading3}
              onClick={() => formik.submitForm()}
            >
              <span style={{position:"relative", top:"1px"}}>LOGIN</span>
            </LoginButton2>
          </Form>
        )}
      </Formik>


      <div className={classes.layer6ActionContainer}>
        <div className={classes.layer6ArrowContainer}>
        <div style={{position:'relative'}} className={classes.line}><div className={classes.arrow}></div></div>
        </div>

        <StyledApplyButton2 onClick={redirectToJoinPage2} variant="text">
          <span style={{position:"relative", top:"1px", color: "black"}}>APPLY NOW</span>
        </StyledApplyButton2>
      </div>

      <Box sx={{ height: 133 }} />

      <FixClockContainer2>
        <WorldClocks color={'black'} />
      </FixClockContainer2>
    </Container2>
  );
}

const ErrorMessage2 = styled(m.div)({
  color: 'red',
  textAlign: 'center'
})

const StyledApplyButton2 = styled(Button)({
  "&.MuiButton-root": {
    color: "white",
    padding: 0,
    fontSize: 15,
    paddingTop: 4,
    paddingLeft: 19,
  },
});

const FixClockContainer2 = styled("div")({
  // @ts-ignore
  "& > #floating-world-clock": {
    position: "static !important",
  },
});

const CheckBoxField2 = styled(Box)({
  "& .MuiTypography-root": {
    color: "white",
    fontSize: 12,
  },
});

const StyledLink2 = styled(Link)({
  color: "#FE4023",
  fontSize: 12,
  position:"relative",
  top:"1px"
});

const LoginButton2 = styled(Button)({
  padding: 0,
  minWidth: 0,
  fontSize: 15,
  color: "#2C62E0",
  lineHeight: "40px",
});

const TypoTitle2 = styled(Typography)({
  color: "black !important",
  fontSize: 15,
  fontWeight: 400,
  paddingBottom: 80,
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
});

const Container2 = styled("div")({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `url(${discoverMobileImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat", 
  height: '100vh',
  padding: "104px 40px 62px 40px",
});
// Customizable Area End