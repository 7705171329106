// Customizable Area Start
import React, { Fragment, useEffect, useState } from "react";
import { CircularProgress, Box, Button, styled } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import QuestionWeb from "./QuestionWeb.web";
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useJoinPageQuestions, usePostAnswers } from "../../../components/src/api/hooks/join-page.hooks";
import { HideContainer, VerticalSpacer } from "../../../components/src/shared";
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from '../../../components/src/utils/router-context';
import { isValidPhoneNumber } from "react-phone-number-input";
// Customizable Area End

// Customizable Area Start
const validationSchema = yup.object().shape({

});

const useStyles = makeStyles((theme) => ({
  blueLines: {
    height: "1px",
    width: "80%",
    position:"relative",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    }
  },
  arrow: {
    width: "9px",
    height: "9px",
    left: "calc(100% - 9px)",
    bottom: "-4px",
    margin: "0",
    position: "absolute",
    transform:"rotate(45deg)",
    borderRight: `1px solid ${theme.palette.primary.main}`,
    borderTop: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('sm')]: {
        width: "9px",
        height: "9px",
        bottom: "-4px",
    },
  },
  sendButton: {
    fontWeight: 600,
    textTransform: "uppercase",
    textAlign: 'right',
    cursor: 'pointer',
    fontSize: "20px",
    position:"relative",
    top:"1px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "15px"
    }
  },
}));

const WrapperContainer = styled('div')({
  "& #jetZeroInputContainer input, & #jetZeroInputContainer label": {
    "@media only screen and (max-width: 960px)": {
      fontSize: 15
    },
    "@media only screen and (max-width: 600px)": {
      fontSize: 10
    }
  }
})
// Customizable Area End

// Customizable Area Start
export function JoinPageForm() {

  const [questionsResponse, setQuestionsResponse] = useState<any[]>([
    {
      "id": "-1",
      "type": "question",
      "attributes": {
          "question": "What's your phone number ?",
          "placeholder": "(5555) 555-5555",
          "question_type": "mobile"
      }
    },
    {
        "id": "-2",
        "type": "question",
        "attributes": {
            "question": "What's your email ?",
            "placeholder": "john@doe.com",
            "question_type": "email"
        }
    },
    {
        "id": "-3",
        "type": "question",
        "attributes": {
            "question": "What's your name ?",
            "placeholder": "John Doe",
            "question_type": "string"
        }
    }
  ])

  //@ts-ignore
  const { data: dynamicQuestions } = useJoinPageQuestions()
  const [phoneError, setPhoneError] = useState<boolean>(false)

  useEffect(()=>{
    dynamicQuestions &&
    setQuestionsResponse((prev:any) => [...prev, ...dynamicQuestions?.data])
  },[dynamicQuestions])

  const { mutate: postAnswer, isLoading: postLoading, error } = usePostAnswers();

  const classes = useStyles();

  const questions = questionsResponse.filter((question:any) => question.attributes.question.length);
  const sortedQuestions = questions?.sort((a: any, b: any) => a.id - b.id);
  const navigate = useNavigate();

  const apiErrors: any = error?.errors ?? null;

  return (
    <WrapperContainer>
      <Formik
        initialValues={{ answers: sortedQuestions?.map(_question => '') ?? [] }}
        validationSchema={validationSchema}
        onSubmit={(values) => {

          setPhoneError(false)

          if(!isValidPhoneNumber(values.answers[2]) ){
            setPhoneError(true)
          } else {

            const dynamicPayload = {};

            dynamicQuestions?.data.sort((a: any, b: any) => a.id - b.id).forEach((questionData:any, index:any) => {
                const question = questionData.attributes.question;
                const answer = values.answers[index + 3];
                //@ts-ignore
                dynamicPayload[question] = answer;
            });
  
            const payload = {
              "name" : values.answers[0],
              "email" : values.answers[1],
              "phone_number" : values.answers[2],
              "dynamic_questions" : dynamicPayload
            }
  
            postAnswer(payload, {
              onSuccess() {
                navigate("/join/thank-you");
              }
            });
          }

        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
      >

        {
          // @ts-ignore
          formik => <Form>
            <div style={{ width: "100%", paddingRight: "40px" }}>
              {
                sortedQuestions?.map((item:any, index:any) => (
                  <>

                    <QuestionWeb
                      key={item.attributes?.question}
                      // @ts-ignore
                      phoneError={phoneError}
                      questionType={item.attributes?.question_type}
                      question={item.attributes?.question}
                      questionNumber={index + 1}
                      placeHolder={item.attributes?.placeholder ?? ''}
                      lastQuestion={index == sortedQuestions.length - 1}
                      value={formik.values?.answers?.[index] ?? ''}
                      showAlert={Boolean(formik.errors?.answers?.[index])}
                      hideAsterisk={item.id>0}
                      handleOnChange={(value) => {

                        const newAnswers = formik.values.answers.slice()
                          .map((answer: string, answerIndex: number) => answerIndex == index ? value : answer);

                        formik.setFieldValue('answers', newAnswers);
                      }}
                    />
                  </>
                ))
              }

              {
                apiErrors ?
                  Object.values(apiErrors).map((item: any, index) => {
                    return (
                      <Fragment key={item[0]}>
                        <Alert
                          aria-label={item[0]}
                          data-testid="alert"
                          severity="error">
                          {item[0]}
                        </Alert>
                        <VerticalSpacer />
                      </Fragment>
                    )
                  })
                  : null

              }
            </div>


            <Box display={'flex'} alignItems="center" justifyContent="flex-end">

              <Box className={classes.blueLines}><div className={classes.arrow}></div></Box>

              <Button
                data-test-id='btn-submit'
                color="primary"
                variant="text"
                className={classes.sendButton}
                type="submit"
                disabled={postLoading}
              >
                {'SEND'}
                <HideContainer hideWhen={!postLoading}>
                  <CircularProgress size={20} style={{ margin: '0 5px' }} color='primary' />
                </HideContainer>
              </Button>

            </Box>

          </Form>
        }
      </Formik>
    </WrapperContainer>
  );
}
// Customizable Area End