import React from "react";
import { Typography, Tabs, Tab } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "../utils/router-context";

const JetZeroTabsContainer = withStyles((theme) => ({
  root: {
    maxWidth: '96vw',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: "''",
      top: '4px',
      left: 0,
      width: '100%',
      height: '1px',
      backgroundColor: theme.palette.primary.main,
    },
  },
  indicator: {
    backgroundColor: 'transparent',
  },
}))(Tabs);

const JetZeroTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    transition: 'color 0.3s ease-in',

    overflow: 'visible',

    '&>span': {
      paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      textTransform: 'uppercase',
      '&>*': {
        fontSize: '10px',
      }
    },

    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      '&::before': {
        content: "''",
        position: 'absolute',
        top: '0px',
        left: '10px',
        display: 'block',
        width: '10px',
        height: '10px',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
      },
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))(Tab);


const useStyles = makeStyles({
  header: {
    color: '#c1c1c1',
    fontSize: '10px',
    textTransform: 'uppercase',
    fontWeight:600
  },
});


export function JetZeroTabs({
  activeIndex,
  onChange,
  header,
  tabsNames,
  onClick
}: {
  activeIndex: number,
  onChange: (activeTabIndex: number) => void,
  onClick?:boolean,
  header: string,
  tabsNames: string[],
}) {

  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <JetZeroTabsContainer
      value={activeIndex + 1}
      onClick={onClick ? ()=>navigate("/account#myTrip") : undefined}
      onChange={(_event, index) => onChange(index - 1)}
      aria-label="fleets"
      variant="scrollable"
      scrollButtons="auto"
    >

      <JetZeroTab
        style={{paddingLeft:"0px"}}
        disabled
        label={
          <Typography className={classes.header}>
            {header}
          </Typography>
        }
        disableRipple
      />

      {
        tabsNames
          .map((tabName, index) => (
            <JetZeroTab
              data-test-id={'proccess-tab-' + tabName}
              key={`${tabName}-${index}`}
              label={<>
                <Typography style={{fontWeight:600, fontSize:"10px"}}>{String(index + 1).padStart(2, '0')}</Typography>
                <Typography style={{fontWeight:600, fontSize:"10px"}}>{tabName}</Typography>
              </>}
              disableRipple
            />
          ))
      }
    </JetZeroTabsContainer>
  );
}
