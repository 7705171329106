import React, { ReactNode, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography, styled } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useRouteInfo } from "../utils/router-context";
import { HideContainer } from "../../../components/src/shared";
import { HashLink } from 'react-router-hash-link';
import clsx from 'clsx';
//import './Footer.module.css'onst nbaa = require('./images/nbaa.png')

const socialMediaLinks = [
  { title: "IG", link: "https://www.instagram.com/flyglobal/" },
  { title: "FB", link: "https://www.facebook.com/flyglobalco/" },
  { title: "LI", link: "https://www.linkedin.com/company/flyglobal/" },
];

const staticPagesLinks = [
  { title: "privacy", link: "/privacyterms#privacy" },
  { title: "terms", link: "/privacyterms#terms" },
  { title: "disclaimer", link: "/privacyterms#disclaimer" },
  { title: "cookies", link: "/privacyterms#cookies" },
];

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: "25px 80px",
    background: "black",
    borderRadius: "20px 20px 0 0",
    maxHeight: "360px",

    backgroundImage: "url(/assets/map-overlay.svg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    display: "grid",
    gridTemplateAreas:
      '"social-media site-map logo" "address year static-pages"',
    // alignItems: "center",
    gridTemplateColumns: "150px 1fr 200px",
    gridRowGap: "200px",
    zIndex: 20,

    overflow: 'hidden',

    [theme.breakpoints.down("sm")]: {
      gridTemplateAreas:
        '"site-map logo" "static-pages address" "social-media year"',
      gridTemplateColumns: "50% 50%",
      gridTemplateRows: "150px",
      gridRowGap: "30px",
      padding: "40px",
    },
  },
  noBorder: {
    borderRadius: '0px',
  },

  address: {
    gridArea: "address",
    alignSelf: "end",
    width: "100%",
    color: "white",
    marginBottom: '40px',
    [theme.breakpoints.down("sm")]: {
      justifySelf: "end",
      textAlign: "right",
      marginBottom: '0px'
    },

    "& p": {
      fontSize: "0.9rem",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
        textAlign: "right",
      },
    },
    "& a": {
      fontSize: "0.9rem",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        whiteSpace: "normal",
        fontSize: "10px",
        textAlign: "right",
      },
    },
  },

  socialMediaLinks: {
    gridArea: "social-media",

    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: '14px',
    },
    "& > *": {
      marginRight: "20px",
      position: "relative",
      width: "30px",
      color: "white",
      textAlign: "center",
      "& > p": {
        fontWeight: 600,
      },
    },
    "& > *:hover": {
      color: theme.palette.primary.main,
    },
    "& > *::after": {
      position: "absolute",
      top: "5px",
      left: "135%",
      content: "''",
      width: "2px",
      height: "10px",
      background: "white",
    },
    "& > *:last-child::after": {
      display: "none",
    },
  },

  staticPagesLinks: {
    gridArea: "static-pages",
    justifySelf: "end",
    marginTop: "-30px",
    [theme.breakpoints.down("sm")]: {
      justifySelf: "start",
      marginTop: "0px",
    },

    "& span": {
      display: 'block',

      [theme.breakpoints.down("sm")]: {
        fontSize: '15px',
        marginTop: '1px',
      },
    },
    "& > *": {
      textAlign: "right",
      textTransform: "uppercase",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
      },
    },

    "& > *:hover": {
      color: theme.palette.primary.main,
    },
  },

  siteMapLinks: {
    gridArea: "site-map",
    justifySelf: "center",

    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",

      justifySelf: "start",
      alignSelf: "start",
    },

    "& p": {
      fontWeight: '600',
    },
    "& > *": {
      textTransform: "uppercase",
      marginRight: "64px",
      color: "white",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
      },
    },

    "& > *:last-child" : {
      marginRight:"0px"
    },

    "& > *:hover": {
      color: theme.palette.primary.main,
    },
  },

  logo: {
    gridArea: "logo",
    justifySelf: "end",

    [theme.breakpoints.down("sm")]: {
      alignSelf: "start",
    },

    color: "white",
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
    fontWeight: 600,
    textTransform: "uppercase",

    position: "relative",

    "&::after": {
      position: "absolute",
      top: "8px",
      right: "-10px",
      content: '"TM"',
      color: "white",
      fontSize: "12px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "6px",
        top: "2px"
      },
      fontWeight: "normal",
    },
  },
  year: {
    gridArea: "year",
    justifySelf: "center",
    alignSelf: "end",
    textAlign: 'right',
    color: "white",

    [theme.breakpoints.down("sm")]: {
      textAlign: 'right',
      justifySelf: "end",
      fontSize: "10px",
    },
  },
  normalLink: {
    color: "white",
    fontFamily: "Atyp Display TRIAL",
    fontSize: "16px !important",
  },
  activeLink: {
    color: theme.palette.primary.main,
    fontFamily: "Atyp Display TRIAL",
    fontSize: "16px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
  footerContent:{
    fontWeight:500,
    fontSize:"15px",
    lineHeight:"18px",

  }
}));

const LinkText = styled("div")({
  //fontSize: 16,
  whiteSpace: 'nowrap',
  lineHeight:'19.2px',
  fontWeight:500,
  fontFamily: "Atyp Display TRIAL",
  '@media only screen and (max-width: 1200px)': {
    fontSize: 12
  }
})

function JetZeroFooter() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { path } = useRouteInfo();
  const isAccountPage = path === '/account'
  const hideBorderRadius = path === "/join/thank-you";
  const [isLoggedIn, setIsLoggedIn] = useState<any>(false)

  useEffect(()=>{
    setIsLoggedIn(sessionStorage.getItem('token'))
  },[])

  const LogInUrls = isLoggedIn ? [
    { title: "My Account", link: "/account#account" },
    { title: "LOGOUT", link: "#", logout: true }
  ] : [
    { title: "LOGIN", link: "/login" }
  ]

  let siteMapLinks = [
    { title: "ABOUT", link: "/discover" },
    { title: "JOIN", link: "/join" },
    { title: "IMPACT", link: "/impact" },
    ...LogInUrls
  ];

  if(isLoggedIn){
    siteMapLinks = siteMapLinks.filter(item => !['join'].includes(item.title))
  }

  function clearTokens() {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = '/'
  }

  return (
    <footer
      className={clsx(classes.footer, {
        [classes.noBorder]: hideBorderRadius,
      })}>

      <Typography className={classes.logo}>{"Global"}</Typography>

      <nav className={classes.siteMapLinks}>
        {siteMapLinks.map(({ title, link, logout }, index) => (
          <Link title={title} to={!logout ? link : '#'} key={title} onClick={() => logout ? clearTokens() : () => window.scrollTo(0, 0)} style={{ textDecoration: "none" }}>
            <LinkText className={clsx( { [classes.activeLink]: path.includes(link) || isAccountPage && title == "My Account"})}>
              <HideContainer hideWhen={isMobileScreen}>
                <span
                  style={{ marginRight: "10px" }}
                  className={(path.includes(link) || isAccountPage && title == "My Account") ? classes.activeLink : classes.normalLink}
                >
                  {String(index + 1).padStart(2, "0")}
                </span>
              </HideContainer>
              {`${title}`}
            </LinkText>
          </Link>
        ))}
      </nav>

      <nav className={classes.staticPagesLinks}>
        {staticPagesLinks.map(({ title, link }) => (
          <HashLink smooth to={link} key={title} style={{ textDecoration: "none" }}>
            <Typography variant="h4" className={link.includes(path) ? clsx(classes.activeLink, classes.footerContent ): classes.footerContent}>{title}</Typography>
          </HashLink>
        ))}
      </nav>

      <nav className={classes.socialMediaLinks}>
        {socialMediaLinks.map(({ title, link }) => (
          <a
            target="_blank"
            href={link}
            key={title}
            style={{ textDecoration: "none" }}
          >
            <Typography className={classes.footerContent} variant="caption">{title}</Typography>
          </a>
        ))}
      </nav>

      <div className={classes.address}>
        
        <a style={{ textDecoration: "none", color: "white" }} className={classes.footerContent} href="mailto:info@global.co">{"INFO[AT]GLOBAL.CO"}</a>
        <Typography className={classes.footerContent}>{"123 MAPLE ST.ANYTOWN, PA 16101"}</Typography>
      </div>

      <Typography className={clsx(classes.year, classes.footerContent)} style={{marginBottom: isMobileScreen ? `0px` : `40px`}}>
        {"©2023"}
      </Typography>
    </footer>
  );
}
//<div><img src={nbaa} className={classes.nbaa}/></div>
///////////////////
/* 

  nbaa : {
    width : "140px",
    marginBottom : "20px",
    filter: "invert(100%) grayscale(100%) brightness(500%)",
    [theme.breakpoints.down('sm')] : {
      width : "100px"
    }
  },
*/
export function FooterLayoutForDesktopOnly({ children }: { children: ReactNode }) {

  const theme = useTheme();
  const isSmalScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {children}
      <HideContainer hideWhen={isSmalScreen}>
        <JetZeroFooter />
      </HideContainer>
    </>
  );
}
///////////////////
export function FooterLayout({ children }: { children: ReactNode }) {
  return (
    <>
      {children}
      <JetZeroFooter />
    </>
  );
}
///////////////////

export default JetZeroFooter;
