export const headerPlane = require('../assets/headerPlane.png')
export const imageOverlay = require('../assets/imageOverlay.png')
export const membershipDiary = require('../assets/membership.png')
export const challenger300 = require('../assets/challenger300.png')
export const challenger604 = require('../assets/challenger604.png')
export const founder = require('../assets/founder.png')
export const Global = require('../assets/GlobalImage.png');
export const discoverImage = require('../assets/headerImage.png');
export const discoverMobileImage = require('../assets/headerMobileImage.png');
export const headerBlackBox = require('../assets/headerBlackBox.png');

export const imapactPlane = require("../assets/impactPlane.png")
