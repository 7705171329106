// Customizable Area Start
import React, { ReactNode } from "react";
import { Typography, useMediaQuery } from "@material-ui/core";
//@ts-ignore
import { motion as mThankyouPage } from "framer-motion";
import ThankYouPageController, { Props } from "./ThankYouPageController.web";
import { makeStyles, styled, useTheme } from '@material-ui/core/styles';
import { discoverImage } from "../../landingpage/src/assets";
import WorldClocks from "../../../components/src/shared/WorldClock";

const useStylesThankyouPage = makeStyles((theme) => ({
  rootThankyouPage: {
    backgroundImage: `url(${discoverImage})`,
    height: '100vh',
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", 
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '270px',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: '40px',
    },
  },
  thankYouTextThankyouPage: {
    color: 'black',
    '& *': {
      fontSize: '3rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
  },
  blueThankyouPage: {
    color: theme.palette.primary.main,
  },
  offsetThankyouPage: {
    position: 'relative',
    left: '70px',
  },
  joinTextThankyouPage: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "28px",
    letterSpacing: "0.2em",
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.primary.main,
      marginBottom: '100px',
    },
  },
}));

const useFixedTextContainerStyelsThankyouPage = makeStyles((theme) => ({
  rootThankyouPage: {
    position: 'fixed',
    left: '110px',
    top: '360px',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      left: 0,
    },
  },

}));

function FixedTextContainerThankyouPage({ children }: { children: ReactNode }) {
  const classesThankyouPage = useFixedTextContainerStyelsThankyouPage();

  return (
    <div className={classesThankyouPage.rootThankyouPage}>
      {children}
    </div>
  );
}
// Customizable Area End
export default class ThankYouPage extends ThankYouPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    return (
      <ThankYouPageContentThankyouPage />
    );
  }
}

function ThankYouPageContentThankyouPage() {
  const themeThankyouPage = useTheme();
  const classesThankyouPage = useStylesThankyouPage();
  const matchesThankyouPage = useMediaQuery(themeThankyouPage.breakpoints.down('sm'));

  return (
    <div style={{ overflowX: "hidden", marginTop: '35px' }}>
      <mThankyouPage.div
        initial={{
          x: "100%",
        }}
        animate={{
          x: "0%",
        }}
        transition={{
          duration: 0.75,
          ease: "easeOut",
        }}
      >

        <div className={classesThankyouPage.rootThankyouPage}>

          <FixedTextContainerThankyouPage>
            <Typography className={classesThankyouPage.joinTextThankyouPage}>
              {'JOIN'}
            </Typography>
          </FixedTextContainerThankyouPage>

          <Typography variant="h1" className={classesThankyouPage.thankYouTextThankyouPage}>
            <Typography variant="caption" className={classesThankyouPage.blueThankyouPage}>
              {'THANK YOU'}
            </Typography>
            <br />
            <Typography variant="caption" className={classesThankyouPage.offsetThankyouPage}>
              {'FOR YOUR INTEREST.'}
            </Typography>
            <br />
            <Typography variant="caption" className={classesThankyouPage.blueThankyouPage}>
              {'A TEAM MEMBER WILL'}
            </Typography>
            <br />
            <Typography variant="caption" className={classesThankyouPage.offsetThankyouPage}>
              {'REACH OUT SHORTLY.'}
            </Typography>
            <br />
          </Typography>

          {matchesThankyouPage && (
            <FixedClockToLeftSideWrapperThankyouPage>
              <WorldClocks color="black" />
            </FixedClockToLeftSideWrapperThankyouPage>
          )}

        </div>

      </mThankyouPage.div>
    </div>
  );
}

export const FixedClockToLeftSideWrapperThankyouPage = styled('div')({
  "@media only screen and (max-width: 768px)": {
    "& > #floating-world-clock": {
      paddingTop: 177,
      paddingBottom: 40,
      position: 'static',
    }
  }
})
// Customizable Area End
